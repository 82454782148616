import React from 'react';
import styled from 'styled-components';
import meAvatar from '../../../pictures/meAvatar.jpg';
import { TitleH1, TitleH2 } from '../../styles/Theme';

const Content = styled.div`
  margin: 3rem 10rem;
  height: 80vh;
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    margin: 3rem 3rem;
    display: flex;
    flex-direction: column;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: ${props =>
    props.theme.secondary ? props.theme.container : props.theme.container};
  color: ${props =>
    props.theme.secondary ? props.theme.secondary : props.theme.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 900px) {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 0;
  }
`;

const Text = styled.div`
  margin-left: 2rem;
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  @media (max-width: 900px) {
    border-top-right-radius: 0;
    border-bottom-left-radius: 25px;
  }
  @media (max-width: 575px) {
    height: 70%;
  }
`;

export const MainPage = () => {
  return (
    <Content className="row">
      <Container className="col col-sm-12 col-lg-6">
        <Text>
          <TitleH1>Hello, I'm Ioanna.</TitleH1>
          <TitleH2>Software Engineer @Threads Styling, Ltd</TitleH2>
        </Text>
      </Container>
      <div className="col col-sm-12 col-lg-6">
        <Avatar src={meAvatar} alt="me" />
      </div>
    </Content>
  );
};
