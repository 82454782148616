import React from 'react';
import Footer from '../components/footer';
import { MainPage } from '../components/Home/Home';
import Navigation from '../components/navigation';

export default class Home extends React.Component {
  render() {
    return (
      <div className="home-page">
        <Navigation>
          <MainPage />
          <Footer />
        </Navigation>
      </div>
    );
  }
}
